<template>
  <Layout>
    <template
      v-if="row.label"
      v-slot:label
    >
      {{ row.label }}
    </template>

    <template
      v-if="row.extra"
      v-slot:extra
    >
      {{ row.extra }}
    </template>

    <template v-slot:input>
      <div class="flex">
        <!-- <toggle-button
          class="mr-2"
          v-bind:value="isEnabled"
          v-bind:sync="true"
          v-bind:disabled="isDisabled"
          v-bind:width="inputWidth"
          v-bind:height="inputHeight"/> -->

        <a
          href="#"
          class="btn btn-dark-inverted icon btn-xs text-center focus:text-gray-100 hover:text-gray-100"
          @click.prevent="onSelect"
        >
          Select Asset
        </a>
        <!--
        <label :for="inputId" class="text-center text-xs leading-tight text-blue-500 hover:underline hover:text-blue-400 cursor-pointer">
          Update <fa-icon icon="file-image" class="fa-fw"/>
        </label> -->
      </div>
      <!--
      <input
        class="hidden"
        v-on:change="handleChange"
        :id="inputId"
        name="asset"
        ref="fileSelector"
        type="file"
        value="Upload Asset"
        accept=".png,.jpg,.jpeg,.gif"
      /> -->
    </template>
  </Layout>
</template>
<script>
import { generateUuid } from '@utils/misc.js'
import inputs from './InputMixin'
import MapAssetSelect from './MapAssetSelect'

export default {
  name: 'TextureAssetInput',

  mixins: [inputs],

  data () {
    return {
      inputId: 'file-input'
    }
  },

  computed: {
    isEnabled () {
      return (this.model.enabled === true)
    }
  },

  mounted () {
    this.generateId()
  },

  methods: {
    generateId () {
      this.inputId = 'file-input-' + generateUuid()
    },

    onSelect () {
      this.$bus.$emit('overlay:modal', {
        component: MapAssetSelect,
        props: {
          callback: this.updateMap
        }
      })
    },

    fetchAssetInfo () {
      if (this.resourceId) {
        this.$store.dispatch('assets/loadAsset', { id: this.resourceId })
      }
    },

    updateMap (asset) {
      this.handleChange(asset)
    }
  }

}

</script>
